import React from 'react';

import Layout from 'components/layout';
import Projects from 'components/projects';

const ProjectsPage = ({ location }: { location: Location }) => (
  <Layout
    seo={{
      title: 'Projects',
    }}
    location={location}
  >
    <Projects />
  </Layout>
);

export default ProjectsPage;

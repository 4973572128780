import React from 'react';

import { PROJECTS } from './content';
import * as Styled from './style';

const Projects = () => (
  <Styled.ProjectsIndex>
    {PROJECTS.map((project, idx) => (
      <Styled.Project key={`${idx}-${project.name}`}>
        <Styled.ProjectLink
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Styled.Title>{project.name}</Styled.Title>
          <Styled.Caption>{project.tools.join(' ✸ ')}</Styled.Caption>
          <Styled.Description>{project.description}</Styled.Description>
        </Styled.ProjectLink>
      </Styled.Project>
    ))}
  </Styled.ProjectsIndex>
);

export default Projects;
export const PROJECTS = [
  {
    name: 'yihwan-dot-kim-v1',
    description: 'Yihwan v1',
    tools: ['Gatsby', 'Emotion'],
    link: 'https://yihwan-v1.netlify.app',
  },
  {
    name: 'Gatsby Dialup',
    description: 'Explore the World Wide Web through Gatsby Dial-up.',
    tools: ['TypeScript', 'Gatsby', 'Emotion'],
    link: 'https://gatsbydialup.com',
  },
  {
    name: 'FeedSince',
    description: 'A Chrome extension that hides old tweets from your feed.',
    tools: ['TypeScript', 'Web Extension', 'Emotion'],
    link: 'https://chrome.google.com/webstore/detail/feedsince/ipoiejdpmlhlhmhmlafcoadfioojlfim',
  },
  {
    name: 'Flights Unknown.',
    description: 'Find flight deals departing from a city near you.',
    tools: ['Gatsby Cloud', 'Sanity', 'Emotion'],
    link: 'https://flightsunknown.com/',
  },
  {
    name: 'Powerlift',
    description: 'Math seems easy and all until you code it in early SwiftUI.',
    tools: ['RevenueCat', 'SwiftUI', 'UIKit'],
    link: 'https://apps.apple.com/app/id1502056323',
  },
  {
    name: 'Restricted Travel Map',
    description: 'An interactive map that tracked travel restrictions due to COVID-19.',
    tools: ['MDX', 'React Simple Maps', 'Gatsby', 'Emotion'],
    link: 'https://restrictedtravelmap.com/',
  },
  {
    name: 'Places SF',
    description: 'Are you in on the secret?',
    tools: ['MapKit', 'SwiftUI', 'UIKit'],
    link: 'https://apps.apple.com/us/app/places-san-francisco/id1497590988',
  },
  {
    name: 'Session – Activity Timer',
    description: 'Count things with my first iOS app!',
    tools: ['SwiftUI', 'UIKit'],
    link: 'https://apps.apple.com/us/app/session-activity-timer/id1493474157',
  },
  {
    name: 'WhatDateTimeIsItRightNow.com',
    description: 'It tells you what date and time it is right now. (Try /expert mode!)',
    tools: ['Gatsby', 'Emotion'],
    link: 'https://whatdatetimeisitrightnow.com/',
  },
  {
    name: 'JavaScript Fun',
    description: 'A random assortment of toy projects and apps built with vanilla JavaScript.',
    tools: ['JavaScript'],
    link: 'https://yihwan.github.io/javascript-fun/',
  },
];
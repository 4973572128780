import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const ProjectsIndex = styled.section`
  ${Shared.indexLayoutStyle}
`;

export const ProjectLink = styled.a`
  ${Shared.indexLinkStyle}
`;

export const Project = styled.article`
  ${Shared.indexArticleStyle}
`;

export const Title = styled.h2`
  ${Shared.indexTitleStyle}
`;

export const Caption = styled.div<{ theme: CustomTheme }>`
  ${({ theme }) => Shared.indexCaptionStyle(theme)}
`;

export const Description = styled.div<{ theme: CustomTheme }>`
  max-width: 700px;
  line-height: 1.6;
  font-family: ${({ theme }) => theme.fonts.serif};
  margin-top: ${SPACER.small};
  display: inline-block;
  font-style: italic;
`;